<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Pb domain" class="mb0">
                <el-input
                  clearable
                  v-model.number="filterForm.domain"
                  placeholder="Please enter Pb domain"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="status" prop="status">
                <el-select
                  v-model="filterForm.status"
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option
                    v-for="item in options.status"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label-width="0" class="mb0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="primary" @click="openDialog('add')">Create</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width:100%;min-height: 70vmin;"
          stripe
          border
          :data="list"
          highlight-current-row
          aria-setsize="mini"
          size="mini"
          class="mb10"
        >
          <el-table-column label="id" prop="id" align="center"></el-table-column>
          <el-table-column label="pd domain" prop="pbDomain" align="center"></el-table-column>
          <el-table-column label="Status" prop="status" align="center"></el-table-column>
          <el-table-column label="Remark" prop="remark" align="center"></el-table-column>
          <el-table-column label="Create Time" prop="createTime" align="center"></el-table-column>
          <el-table-column label="Update Time" prop="updateTime" align="center"></el-table-column>
          <el-table-column label="Action" align="center">
            <template slot-scope="scope">
              <el-button
                type="danger"
                size="mini"
                @click="openDialog('off', scope.row)"
                :disabled="scope.row.status == 'pendding'"
                >Off</el-button
              >
              <el-button type="primary" size="mini" @click="openDialog('edit', scope.row)"
                >Edit</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="dialog.title"
          :visible.sync="dialog.visible"
          width="30%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
        >
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="90px"
            :rules="rules"
            v-if="dialog.type !== 'off'"
          >
            <el-form-item label="Pb domain" prop="pbDomain">
              <el-input
                v-model="postData.pbDomain"
                :disabled="postData.status == 'active' || postData.status == 'deprecated'"
                placeholder="Please enter pb domain"
              ></el-input>
            </el-form-item>
            <!--<el-form-item label="status" prop="status">
              <el-select v-model="postData.status" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in options.status"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="Remark" prop="remark">
              <el-input
                v-model.number="postData.remark"
                placeholder="Please enter remark"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-form
            v-model="offData"
            size="small"
            :model="offData"
            ref="offDataForm"
            label-width="110px"
            :rules="rules"
            v-else
          >
            <el-form-item label="原PB域名" prop="oldDomain">
              <el-input
                disabled
                v-model="offData.oldDomain"
                placeholder="Please enter pb domain"
              ></el-input>
            </el-form-item>
            <el-form-item label="新PB域名" prop="newDomain">
              <el-select
                v-model="offData.newDomain"
                placeholder="Please select"
                class="w100"
                filterable
              >
                <el-option
                  v-for="item in options.newDomain"
                  :key="item.id"
                  :label="item.pbDomain"
                  :value="item.pbDomain"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button
              @click="closeDialog(dialog.type === 'off' ? 'offDataForm' : 'postDataForm')"
              size="mini"
              >取 消</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="submitDialog(dialog.type === 'off' ? 'offDataForm' : 'postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import adjustPBCon from '../controllers/setting/adjustPB';
  export default {
    name: 'adjustPBList',
    ...adjustPBCon,
  };
</script>

<style></style>
