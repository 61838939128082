import {
  fetchAdjustPBNewDomain,
  fetchAdjustPBPage,
  handleAdjustPBAdd,
  handleAdjustPBOff,
  handleSupplementDelete,
} from 'api/setting';
import Pagination from '@/components/pagination';
import dialog from 'element-ui/packages/dialog';

const postDatas = {
  pbDomain: '',
  status: '',
  remark: '',
};
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
      },
      options: {
        status: [
          {
            label: 'active',
            value: 'active',
          },
          {
            label: 'pendding',
            value: 'pendding',
          },
          {
            label: 'deprecated',
            value: 'deprecated',
          },
        ],
        newDomain: [],
      },
      total: 0,
      postData: {},
      offData: {
        newDomain: '',
      },
      list: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      dialog: {
        visible: false,
        title: '--',
        type: '',
      },
      rules: {
        pbDomain: [{ required: true, message: 'Pb domain 不能为空' }],
        status: [{ required: true, message: 'status不能为空' }],
        newDomain: [{ required: true, message: '新 pb domain不能为空' }],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      this.loading.list = true;
      fetchAdjustPBPage(this.filterForm)
        .then((res) => {
          if (res.code == 200) {
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 删除
    deletes(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          handleSupplementDelete(id).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功！');
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 打开弹窗
    openDialog(type, row) {
      const _this = this;
      this.dialog.type = type;
      switch (type) {
        case 'add':
          _this.dialog.title = '添加';
          break;

        case 'edit':
          _this.dialog.title = '编辑';
          break;
        case 'off':
          _this.getNewDomainList();
          _this.dialog.title = 'Off';
          break;
        default:
          _this.dialog.title = '';
      }
      this.dialog.visible = true;
      if (type === 'edit' && row) {
        this.postData = Object.assign({}, row);
      }
      if (type === 'off' && row) {
        this.offData.oldDomain = row.pbDomain;
        this.offData.oldDomainStatus = row.status;
      }
    },
    // 提交弹窗
    submitDialog(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading.submitBtn = true;
          if (this.dialog.type !== 'off') {
            handleAdjustPBAdd(this.postData).then((res) => {
              if (res.code == 200) {
                this.$message.success(
                  this.dialog.type === 'add'
                    ? '添加成功!'
                    : this.dialog.type === 'edit'
                    ? '更新成功！'
                    : ''
                );
                this.loading.submitBtn = false;
                this.closeDialog(formName);
                this.getList();
              } else {
                this.$message.error(res.message);
                this.loading.submitBtn = false;
              }
            });
          } else {
            handleAdjustPBOff(this.offData).then((res) => {
              if (res.code === 200) {
                this.$message.success('关闭成功！');
                this.closeDialog(formName);
                this.getList();
              } else {
                this.$message.error(res.message);
              }
              this.loading.submitBtn = false;
            });
          }
          this.$refs[formName].clearValidate();
        }
      });
    },
    getNewDomainList() {
      fetchAdjustPBNewDomain().then((res) => {
        this.options.newDomain = res.result;
      });
    },
    // 关闭弹窗
    closeDialog(formName) {
      this.$refs[formName].resetFields();
      if (dialog.type === 'off') {
        this.offData = {};
      } else {
        this.postData = postDatas;
      }
      this.dialog.visible = false;
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
  },
};
